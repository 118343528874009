<template>

  <div class="fixed z-10 inset-0 overflow-y-auto" id="bottom-modal">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

      <div class="fixed inset-0 transition-opacity" @click="$emit('close')" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

<!--
        :class="[
          {'bg-gray-100': template != 3},
          {'bg-template4-dark text-template4-textColor': template == 3}
        ]"-->
      <div
        class="inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all mt-8 w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div
          class="bg-gray-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bottom-modal-card">
          <div class="flex">
            <div class="mt-3 text-center w-full" >
              <h3 class="text-lg leading-6 font-medium text-gray-900 pb-4" id="modal-headline" v-if="title">{{ title }}</h3>
              <div class="mt-2 overflow-y-scroll w-full overflow-y-auto" style="max-height: calc(100vh - 200px)">
                <slot name="body"/>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">-->
<!--          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Deactivate</button>-->
<!--          <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>-->
<!--        </div>-->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BottomModal",
  props: {
    title: {
      default: '',
      type: String,
    }
  },
  data() {
    return {
      template: 0,
    }
  },
  mounted() {
    this.template = localStorage.getItem('template');
  }
}
</script>

<style scoped>

</style>
